import $ from "jquery";

$(function() {
    $(".bl-accordion").on("click", ".summary", function() {
        const $accitem = $(this).closest(".accitem");

        if(!$accitem.hasClass("hascontent1")) {
            return;
        }

        const isexpanded = $accitem.hasClass("expanded");

        const $details = $accitem.find(".details");
        const animspeed = 400;

        if(!isexpanded) {
            $accitem.addClass("expanded");
            $details.slideDown(animspeed);    
        } else {
            $accitem.removeClass("expanded");
            $details.slideUp(animspeed);    
        }

        $(".morelink-close").on("click", function() {
            $accitem.removeClass("expanded");
            $details.slideUp(animspeed);
        });

    });

});