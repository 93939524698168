import $ from "jquery";

function getModalPopup() {
    return $("#modalpopup");
}

function openModalPopup(url) {
    const $modalPopup = getModalPopup();
    $modalPopup.find("iframe").attr("src", url);
    $modalPopup.fadeIn(200);
}

function closeModalPopup() {
    const $modalPopup = getModalPopup();
    $modalPopup.fadeOut(200);
}

function initModalPopup() {
    const $modalPopup = getModalPopup();

    $('a[href*="modal-terminanfrage"]').on("click", function(ev) {
        ev.preventDefault();
        //const $a = $(this);
        //const url = $a.attr("href");
        //openModalPopup("https://m.cbooking.de/v2/Booking.aspx?id=weisseskreuz&lang="+getLanguage());
        //openModalPopup(url);
        openModalPopup();
    });
    $modalPopup.find(".modalbg, .closebutton").on("click", closeModalPopup);
}

function getLanguage() {
    const lang = $("html").attr("lang");
    if(!lang) {
        return "de";
    } else {
        return lang;
    }
}

$(function() {
    initModalPopup();
});

