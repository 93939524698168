import $ from "jquery";

$(() => {
  const arrowScroll = document.getElementById("totopbutton");

  if (arrowScroll) {
    arrowScroll.addEventListener("click", (e) => {
      if (arrowOverFooter()) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        const pageHeight = window.innerHeight;
        window.scrollBy({ top: pageHeight, behavior: "smooth" });
      }
    });

    window.addEventListener("scroll", () => {
      let rotation = 0;
      let inverted = false;
      if (arrowOverFooter()) {
        rotation = 180;
        inverted = true;
      }
      arrowScroll.children[0].style.transform = `rotate(${rotation}deg)`;
      
      if(inverted) {
        arrowScroll.children[0].style.filter = `invert(1)`;      
      } else {
        arrowScroll.children[0].style.filter = ``;      
      }
    });
  }
});

function arrowOverFooter() {
  const footerHeight = document.getElementsByTagName("footer")[0].clientHeight;
  const documentScrollHeight = document.documentElement.scrollHeight;
  const beginningOfFooter = documentScrollHeight - footerHeight;

  const arrowScrollHeight = window.scrollY + window.innerHeight - 50;
  return arrowScrollHeight > beginningOfFooter;
}

