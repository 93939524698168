import $ from "jquery";
import "jquery.scrollto";

document.body.addEventListener("custom:formsubmit", function(ev) {
    const formname = ev.detail;
    if(formname == "contactform") {
        const headerHeight = $("header").outerHeight();

        window.scrollTo({ top: 0, behavior: "smooth" });
        $(window).scrollTo($(".form-contactform"), 1000, {
            offset: -headerHeight
        });
    }
});

/*
const contactform = document.getElementsByClassName("form-contactform");
  if(contactform.length > 0) {
    contactform[0].scrollIntoView({behavior: "smooth"});
  }
*/